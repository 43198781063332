.container {
  display: flex;
  justify-content: center;
  margin-top: var(--default-layout-header-height);
  height: 100%;
}

.inner {
  width: 320px;
}
