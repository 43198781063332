.container {
  width: 100%;
  height: 100%;
}

.inner {
  padding-right: 41.5%;
  padding-bottom: 26.5%;
  width: 100%;
  position: relative;
}

.extra-image {
  width: 60%;
  position: absolute;
  bottom: 0;
  right: 0;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .container {
    max-width: 100%;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .right-image {
    border-radius: 24px;
    width: 240px;
    height: 360px;
    object-fit: cover;
  }

  .left-image {
    width: 180px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
