.tabs-container {
  padding: 30px;
  max-width: 100vw;
  margin-top: var(--default-layout-header-height);
}

.back-button {
  height: 30px;
  width: 30px;
  cursor: pointer;
}
