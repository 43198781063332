.container {
  display: flex;
  flex-wrap: wrap;
}

.radio-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
  flex: 1;
}

.radio-item:active,
.radio-item:hover {
  background-color: #fafafa;
}

.radio-dot {
  margin-right: 12px;
}

//item spacing
.item {
  background-color: white;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.details {
  padding: 10px 16px;
}

.top {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.imageContainer {
  height: 40px;
  width: 140px;
}

.info-column {
  display: flex;
  flex-direction: column;
}

.titleContainer {
  //   borderleftcolor: "red";
  //   borderleftwidth: 1;
  //   flex: 1;
  padding: 10px 0 0;
}
.title {
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.subTitle {
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.4);
}
.image {
  height: 40px;
  width: 100px;
  //   resizemode: "contain";
}

.logo {
  height: 40px;
  width: 100px;
  object-fit: contain;
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 16px;
}
.accentText {
  font-size: 24px;
  font-weight: 600;
}
.more {
  background-color: #f5f5f5;
  padding: 16px;
  margin: 10px 0 0;
  cursor: pointer;
}
.highlight {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.highlightspan {
  //   margin: 0 6px 0 0;
  color: blue;
  font-size: 16px;
  font-weight: 500;
}
.tips {
  border: 1px solid grey;
  padding: 16px;
  border-radius: 10;
  margin: 10px 0;
}
