.container {
  height: 100%;
  display: flex;
  justify-content: center;
}

.inner {
  display: flex;
  height: 100%;
  // max-width: 1456px;
  width: 100%;
}

.left {
  flex: 0.3;
  background-color: #e3f8ef;
  height: 100%;
  padding-left: 72px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 60px;
  display: flex;
  flex-direction: column;
}

.preHeading {
  font: 400 14px/22px Work Sans, sans-serif;
  margin-bottom: 32px;
  text-transform: uppercase;
}

.title {
  margin-bottom: 16px;
  font: 400 24px/32px Work Sans, sans-serif;
  letter-spacing: -0.02em;
  font-weight: 500;
}

.text {
  flex: 1;
  margin-bottom: 40px;
}

.right {
  flex: 0.7;
  padding-left: 24px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 60px;
}

.preHeadingRight {
  font: 400 14px/22px Work Sans, sans-serif;
  margin-bottom: 32px;
  text-transform: uppercase;
  padding-left: 32px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
