.wrapper {
  --item-width: min(calc((100vw - 40px - 144px) / 6 + 40px), 276px);
  --item-appearance: calc(var(--item-width) - 40px);

  flex: 1;
  padding-left: 72px;
  padding-right: 72px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.center {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1456px;
}

.container {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 300px;
}

.title-container {
  display: flex;
  width: 100%;
  max-width: 1456px;
}

.title {
  font: 400 20px/28px Work Sans, sans-serif;
  font-size: 32px;
  padding-bottom: 24px;
  color: #28293c;
}

.item1,
.item2,
.item3,
.item4,
.item5,
.item6,
.item7,
.item8 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--item-width);
  height: 100%;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: absolute;
  padding-top: 20px;
  padding-bottom: 20px;
}
.itemContainer {
  height: 100%;
  padding: 20px 40px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item1:hover,
.item2:hover,
.item3:hover,
.item4:hover,
.item5:hover,
.item6:hover,
.item7:hover,
.item8:hover {
  transform: rotate(3deg) scale(1.05);
}

.item1 {
  background-color: #35cf88;
  left: 0;
}

.item2 {
  background-color: #44d391;
  left: var(--item-appearance);
}

.item3 {
  background-color: #54d79b;
  left: calc(var(--item-appearance) * 2);
}

.item4 {
  background-color: #64daa4;
  left: calc(var(--item-appearance) * 3);
}

.item5 {
  background-color: #74dead;
  left: calc(var(--item-appearance) * 4);
}

.item6 {
  background-color: #84e2b7;
  left: calc(var(--item-appearance) * 5);
}

.imageContainer {
  height: 32px;
  width: 32px;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.item-text-container {
  flex: 1;
  padding-top: 20px;
  padding-bottom: 20px;
}

.item-title {
  font: 400 16px/24px Work Sans, sans-serif;
}

.item-subtitle {
  font: 400 14px/22px Work Sans, sans-serif;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media only screen and (max-width: 63.9375em) {
  .container {
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    height: 500px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .item1,
  .item2,
  .item3,
  .item4,
  .item5,
  .item6 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s ease;
    height: 100px;
    padding-top: 20px;
    padding-bottom: 40px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .itemContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0;
    padding-bottom: 0;
    flex-direction: row;
  }

  .item1:hover,
  .item2:hover,
  .item3:hover,
  .item4:hover,
  .item5:hover,
  .item6:hover {
    transform: none;
  }

  .item1 {
    top: 0;
  }

  .item2 {
    top: 80px;
    left: 0;
  }

  .item3 {
    top: 160px;
    left: 0;
  }

  .item4 {
    top: 240px;
    left: 0;
  }

  .item5 {
    top: 320px;
    left: 0;
  }

  .item6 {
    top: 400px;
    left: 0;
  }

  .item-text-container {
    padding-left: 24px;
  }

  .item-subtitle {
    display: none;
  }
}
