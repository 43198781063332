.App {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  overflow: hidden; /* Thêm thuộc tính này */
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 20px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap; /* Thêm thuộc tính này */
  overflow: hidden;
}

.image {
  width: 100%; /* Thay đổi thuộc tính này */
  max-width: 450px; /* Thêm thuộc tính này */
}

.card-content {
  padding: 20px;
  flex: 1; /* Thêm thuộc tính này */
}

.card-title {
  font-size: 24px;
}

.card-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25%;
  padding-right: 25%;
}

.referral-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Thêm thuộc tính này */
  box-sizing: border-box; /* Thêm thuộc tính này */
}

.copy-link {
  text-align: center;
  margin: 20px 0 40px;
  width: 100%; /* Thay đổi thuộc tính này */
  max-width: 400px; /* Thêm thuộc tính này */
}

.link-input {
  padding: 12px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1.6rem;
  margin-bottom: 20px;
  background-color: #fff;
  width: 100%; /* Đảm bảo input chiếm đủ chiều rộng của container */
  box-sizing: border-box; /* Đảm bảo padding và border không làm tăng chiều rộng tổng */
}

.form-label {
  text-align: left;
}

.message-input {
  width: 100%;
  padding: 12px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 1.6rem;
  text-align: left;
  display: block;
  margin: 0 auto;
  overflow: auto;
  resize: vertical;
  margin-bottom: 20px;
  box-sizing: border-box; /* Thêm thuộc tính này */
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .copy-link {
    max-width: 360px;
  }

  .card {
    flex-direction: column;
  }
  .image {
    width: 100%;
    max-width: none; /* Thêm thuộc tính này */
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .copy-link {
    width: 100%;
  }
  .card {
    flex-direction: column;
  }
  .image {
    width: 100%;
    max-width: none; /* Thêm thuộc tính này */
  }
}
