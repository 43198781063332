.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  width: 100%;
  height: var(--default-layout-header-height);
  background-color: var(--white);
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

.inner {
  height: 100%;
  width: var(--default-layout-width);
  padding: 0 var(--default-layout-horizontal-spacer);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-link {
  display: flex;
  margin-right: 60px;
  padding-left: 12px;
  font-family: "Roboto", Arial, sans-serif;
  color: rgb(40, 205, 65);
  font-size: 32px;
}

.logo {
  height: 40px;
  margin-right: 8px;
}

.name {
  color: var(--primary);
  font-size: 22px;
  font-family: "Roboto Bold";
  display: flex;
  align-items: flex-end;
}

// Actions
.actions {
  display: flex;
  flex: 1;
}

.action-btn {
  position: relative;
  display: flex;
  color: rgba(0, 0, 0, 0.8);
  background-color: transparent;
  cursor: pointer;
  align-items: center;
  margin-right: 48px;
}

.action-icon {
  position: relative;
  display: flex;
  color: rgba(0, 0, 0, 0.8);
  background-color: transparent;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  display: none;
}

.action-note {
  font-size: 16px;
  margin-right: 12px;
}

.profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  cursor: pointer;

  .profile-image {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .action-btn {
    display: none;
  }

  .action-icon {
    display: flex;
  }

  .actions {
    flex: 0;
  }

  .logo-link {
    padding-left: 4px;
  }

  .logo {
    width: 30px;
  }

  .name {
    font-size: 18px;
  }

  .profile-name {
    display: none;
  }

  .login-button {
    display: none;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .action-btn {
    display: none;
  }

  .action-icon {
    display: flex;
  }

  .actions {
    flex: 0;
  }

  .profile-name {
    display: none;
  }
}
