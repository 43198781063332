.wrapper {
  min-height: 95px;
  margin-bottom: 12px;
  width: 100%;
  max-width: 498px;
}

.container {
  position: relative;
  display: flex;
  box-sizing: border-box;
  color: rgb(5, 71, 82);
  background-color: rgb(237, 237, 237);
  border-radius: 4px;
  border: 3px solid rgb(237, 237, 237);
  box-shadow: none;
}

.container:focus-within {
  border: 3px solid rgb(0, 175, 245);
}

.title {
  margin-bottom: 8px;
  font-size: 18px;
}

.input {
  padding-right: 0px;
  font-family: inherit;
  // margin-right: 48px;
  appearance: none;
  border: 0px;
  border-radius: 4px;
  background-color: rgb(237, 237, 237);
  color: rgb(5, 71, 82);
  flex: 1 1 0%;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  caret-color: rgb(0, 175, 245);
  padding: 16px;
}

.input:focus {
  border-color: rgb(237, 237, 237);
  box-shadow: none;
}

.bottom {
  color: rgb(0, 175, 245);
  padding: 12px;
}
