.container {
  padding-left: 72px;
  padding-right: 72px;
}

.inner {
}

.section {
  border-bottom: 1px solid #e5e5e5;
  overflow: auto;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  color: #28293c;
  font-weight: 400;
  font-size: 18px;
  font-family: Work Sans, sans-serif;
  letter-spacing: -0.02em;
  cursor: pointer;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .container {
    padding-left: 16px;
    padding-right: 24px;
  }
}
