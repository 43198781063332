.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 100%;
  padding: 0px;
  width: 100%;
  max-width: 100%;
  // margin-top: var(--default-layout-header-height);
  display: flex;
}

.content {
  flex: 1;
  // justify-content: center;
  // display: flex;
}
