.avatarUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatarPreview {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1rem;
  border: 2px solid rgba(0, 0, 0, 0.3);
}

.avatarPreview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fileInput {
  display: none;
}

.uploadButton {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.uploadButton:hover {
  background-color: #0056b3;
}
