.container {
  padding-left: 56px;
  padding-right: 72px;
  color: #28293c;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  max-width: 1456px;
  width: 100%;
}

.heading {
  color: #28293c;
  padding-left: 16px;
}
.title {
  font: 400 32px/40px Work Sans, sans-serif;
  margin-bottom: 12px;
  letter-spacing: -0.02em;
}
.sub-title {
  font: 400 16px/24px Work Sans, sans-serif;
  margin-bottom: 32px;
}

.intro-list {
  display: table;
  width: 100%;
  border-spacing: 16px;
}

.intro-item {
  border: 1px solid #28293c;
  border-radius: 24px;
  align-items: baseline;
  display: table-cell;
  padding: 32px;
  margin-bottom: 24px;
  width: 33%;
}

.icon-item {
  padding-bottom: 64px;
}

.intro-item-title {
  padding-bottom: 16px;
  font: 400 24px/32px Work Sans, sans-serif;
  letter-spacing: -0.02em;
  flex: 1;
}

.intro-item-subtitle {
  font: 400 16px/24px Work Sans, sans-serif;
}

.item-subtitle::before {
  display: block;
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 2px;
  background-color: #3957e1;
  top: 12px;
  left: 0;
  margin-top: -3px;
}

.item-subtitle {
  position: relative;
  padding-left: 16px;
  margin-bottom: 16px;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .container {
    padding-left: 56px;
    padding-right: 56px;
  }
  .container {
    padding-left: 0;
    padding-right: 0;
  }
  .intro-list {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }

  .heading {
    padding-left: 16px;
    padding-right: 16px;
  }

  .intro-item {
    width: 100%;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .container {
    padding-left: 56px;
    padding-right: 56px;
  }
  .intro-list {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }

  .heading {
    padding-left: 16px;
    padding-right: 16px;
  }

  .intro-item {
    width: 100%;
  }
}
