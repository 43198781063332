.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pre {
  font: 400 12px/20px Work Sans, sans-serif;
  padding-bottom: 12px;
}

.title {
  font: 400 64px/72px Work Sans, sans-serif;
  letter-spacing: -0.02em;
  color: #28293c;
  margin-bottom: 24px;
}

.subTitle {
  font: 400 16px/24px Work Sans, sans-serif;
  color: #28293c;
  margin-bottom: 48px;
}

.icons {
  display: flex;
}

.icon-appstore {
  margin-right: 24px;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .inner {
    width: 100%;
    margin-bottom: 20px;
  }
  .title {
    font: 400 32px/36px Work Sans, sans-serif;
    height: 100px;
  }

  .subTitle {
    margin-bottom: 24px;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .inner {
    width: 100%;
    margin-bottom: 20px;
  }

  .title {
    font-size: 42px;
    line-height: normal;
  }
}
