.graphics-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.svg-polyfill {
  position: relative;
  width: 100%;
  height: 100%;
}

.svg-polyfill-active {
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg-polyfill-render {
  position: absolute;
  border-radius: 24px;
}

.bg-beige {
  background-color: #f1f1f1;
}

.bg-blue {
  background-color: #54d79b;
}

.svg-image-video {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 24px;
}

.svg-image-video-video {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 24px; // Đảm bảo video được bo tròn
}

.svg-image-video-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% center;
  border-radius: 24px; // Đảm bảo ảnh được bo tròn
}
