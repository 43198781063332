.main {
  margin-top: var(--default-layout-header-height);
  font-family: "Arial";
  background-color: white;
}

.icons {
  display: flex;
}

.icon-appstore {
  margin-right: 24px;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
}
