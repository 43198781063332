.pre {
  font: 400 12px/20px Work Sans, sans-serif;
  padding-bottom: 12px;
}

.title {
  font: 400 32px/40px Work Sans, sans-serif;
  letter-spacing: -0.02em;
  color: #28293c;
  padding-bottom: 16px;
}
.subTitle {
  font: 400 16px/24px Work Sans, sans-serif;
  color: #28293c;
  margin-bottom: 48px;
}

.icons {
  display: flex;
  margin-top: 48px;
}

.icon-appstore {
  margin-right: 24px;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
}
