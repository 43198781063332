.radio-item {
  display: flex;
  align-items: center;
  /* border: 1px solid black; */
  width: 100%;
  padding: 10px 12px;
  /* border-radius: 4px; */
  cursor: pointer;
}

.radio-item:hover {
  /* border: 1px solid red; */
}

.radio-item:hover .radio-item-text {
  color: red;
}

.radio-item-text {
  flex: 1;
  margin-left: 12px;
}

.radio-item-circle {
  border: 1px solid red;
  height: 16px;
  width: 16px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-item-circle-inner {
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background-color: red;
}
