.main {
  margin-top: var(--default-layout-header-height);
  font-family: "Arial";
  background-color: white;
  // min-height: 600px;
  padding: 72px;
  max-width: 960px;
}

.section {
  display: flex;
  padding: 24px 0;
  width: 100%;
}

.left {
  flex: 1;
}

.title {
  font-size: 18px;
}

.right {
  flex: 1;
  padding-left: 24px;
}

.input-wrapper {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.input {
  background-color: transparent;
  padding: 12px;
  font-size: 16px;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .main {
    padding: 36px 24px;
  }

  .section {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    width: 100%;
  }

  .right {
    flex: 1;
    padding-left: 0;
    padding-top: 24px;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
}
