/* ForgotPassword.module.scss */
.main {
  max-width: 400px;
  margin: 0 auto;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: calc(var(--default-layout-header-height) + 40px);
  font-family: "Arial";
}

.heading {
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 500;
}

.content {
  margin-bottom: 1rem;
}

.title-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
}

.success-message {
  color: green;
}

.bottom {
  text-align: center;
}
