.container {
  display: flex;
  flex-wrap: wrap;
}

.radio-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
}

.radio-item:active,
.radio-item:hover {
  background-color: #fafafa;
}

.radio-dot {
  margin-right: 12px;
}
