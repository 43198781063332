// ProfilePage.module.scss
.profilePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.label {
  margin-bottom: 5px;
  font-weight: bold;
}

.input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  font-size: 16px;
}

.updateButton {
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
}
