.main {
  font-family: "Arial";
  background-color: white;
  // min-height: 600px;
  padding: 48px 72px 240px;
  max-width: 960px;
  display: flex;
  justify-content: center;
}

.heading {
  margin-bottom: 24px;
}

.section {
  display: flex;
  flex-direction: column;
  width: 320px;
  margin-bottom: 24px;
}

.title {
  font-size: 18px;
  margin-bottom: 12px;
}

.input-wrapper {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.input {
  background-color: transparent;
  padding: 12px;
  font-size: 16px;
  width: 100%;
}

.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px 72px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.bottom-row {
  width: 320px;
  display: flex;
  align-items: center;
}

.bottom-title {
  flex: 1;
  font-size: 16px;
}

.bottom-note {
  width: 320px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 0.8;
  cursor: pointer;
}

.bottom-number {
  font-size: 18px;
  font-weight: bold;
  color: #ff7800;
}

.inner {
  width: 320px;
  margin-top: 20px;
}

.cashback-note {
  padding: 48px;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .main {
    padding: 36px 24px 240px;
  }

  .section {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    width: 100%;
  }

  .right {
    flex: 1;
    padding-left: 0;
    padding-top: 12px;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
}
