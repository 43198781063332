.container {
  padding: 20px;
}

.heading {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.filters {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.filters label {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
}

.filterSelect {
  margin-top: 8px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.filterSelect:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.tableWrapper {
  margin-top: 20px;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table th {
  background-color: #f8f9fa;
  font-weight: 600;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.status {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  color: darkorange;
}

.status.cancel {
  color: #ef392f;
}

.status.completed {
  color: #0ed235;
}

.buttonGroup {
  display: flex;
  gap: 10px;
}

.button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
}

.cancelButton {
  background-color: #dc3545;
}

.completeButton {
  background-color: #28a745;
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
}

.popupButtonGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.popupButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.skipButton {
  background-color: #d1e7dd;
  color: #055160;
}

.confirmButton {
  background-color: #ddd;
  color: rgb(228, 29, 29);
}

.cancelReasons {
  padding: 20px 0;
}

.radioContainer {
  //   display: flex;
  //   align-items: center; /* Căn giữa theo chiều dọc */
  cursor: pointer; /* Con trỏ chuột khi hover vào label */
  margin-left: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 12px;
}

.radio {
  height: 16px;
  width: 16px;
  border-radius: 10px;
  border: 1px solid #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: #007bff;
}

.radioLabel {
  cursor: pointer;
}
