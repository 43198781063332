.container {
  width: 100%;
  height: 100%;
}

.inner {
  padding-left: 10%;
  padding-bottom: 5%;
  width: 100%;
  height: 100%;
  position: relative;
}

.extra-image {
  width: 242px;
  position: absolute;
  bottom: 0;
  left: 0;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .container {
    max-width: 100%;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
}
