.section {
  display: flex;
  padding: 24px 0;
  width: 100%;
  flex-direction: column;
}

.left {
  flex: 1;
}

.title {
  font-size: 18px;
}

.right {
  flex: 1;
  margin-top: 24px;
  display: flex;
}

.input-wrapper {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  flex: 1;
}

.elevator-container {
  flex: 1;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  background-color: transparent;
  padding: 12px;
}

.elevator-checkbox {
  margin-right: 10px;
}
