.container {
  margin-top: var(--default-layout-header-height);
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding-top: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: calc(var(--default-layout-header-height) + 40px);
  font-family: "Arial";
}

.content {
  padding: 20px;
}

.phone-number {
  display: flex;
  justify-content: space-between;
  // height: 44px;
  // line-height: 44px;
  box-sizing: content-box;
  border-radius: 4px;
  position: relative;
  margin-bottom: 12px;
  background: var(--border-color);
  border: 1.5px solid var(--border-color);
  user-select: none;
  font-size: 14px;
  width: 320px;
}
.flag {
  display: flex;
  align-items: center;
  margin: auto 10px;
}

.icon-flag {
  margin-right: 4px;
}

.phone-number-input {
  border-radius: 0px 4px 4px 0px;
  flex: 1;
  padding-left: 10px;
  font-family: "ProximaNova-regular";
  font-size: 16px;
  color: #161823;
  background-color: transparent;

  &:focus {
    border: none;
  }
}
//form .phone-number
.form {
  flex-direction: column;
}

.phoneContainer {
  display: flex;
  // justify-content: center;
  flex-direction: row;
  align-items: center;
  background-color: rgb(237, 237, 237);
  padding-left: 20px;
  margin-bottom: 40px;
}

.country {
  font-size: 18px;
}

.phone-input {
  padding: 12px 10px;
  border: 3px solid black;
  font-size: 18px;
  border-radius: 4px;
  caret-color: var(--primary);
  position: relative;
  display: flex;
  box-sizing: border-box;
  color: rgb(5, 71, 82);
  background-color: rgb(237, 237, 237);
  border-radius: 4px;
  border: 3px solid rgb(237, 237, 237);
  box-shadow: none;
  min-height: 52px;
}

.error {
  height: 44px;
  line-height: 44px;
  border-radius: 4px;
  color: red;
  user-select: none;
  font-size: 14px;
  width: 320px;
}

.button {
  display: flex;
  justify-content: center;
}

.header {
  text-align: center;
  margin-bottom: 30px;
}
.title-container {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 18px;
  margin-bottom: 12px;
  font-weight: 500;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.row {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
  height: 42px;
}

.input-wrapper {
  flex: 2;
  background-color: #f3f4f8;
  border-radius: 4px;
}

.input {
  width: 100%;
  padding: 14px;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 16px;
}

.bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding: 12px 10px;
  display: flex;
  justify-content: center;
}
