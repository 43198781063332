.item {
  position: relative;
  height: 400px;
  background-color: lightGrey;
  overflow: hidden;
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 30%;
  margin-bottom: 48px;
  margin-right: 48px;

  .item__title {
    margin: 0;
    display: block;
    background-color: white;
    padding: 24px 24px 0 24px;
    border-radius: 20px;
    height: 15rem;
  }

  .item__title-first {
    padding-bottom: 12px;
    font: 400 20px/28px Work Sans, sans-serif;
  }

  .item__title-second {
    color: gray;
    padding-bottom: 12px;
    font: 400 16px/24px Work Sans, sans-serif;
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.item__overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  top: 0;
  transition: transform 300ms, background-color 300ms;
  transform: translate3d(0, calc(100% - 15rem), 0);
}

.item__body {
  flex-grow: 1;
  padding: 0 24px 24px 24px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .item__title-third {
    padding-bottom: 12px;
    font: 400 14px/22px Work Sans, sans-serif;
    flex: 1;
    margin: 0;
  }
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .item {
    width: 100%;
    margin-right: 0;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .item {
    width: 42%;
  }
}
