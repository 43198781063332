.dashboardContainer {
  display: flex;
  margin-top: var(--default-layout-header-height);
  font-family: "Arial";
  background-color: white;
}

.content {
  padding: 20px;
}
