@import "normalize.css";

:root {
  --primary: #0b2533;
  --secondary: #d93364;
  --hover: #124d6e;
  --logo-color: #2abd3d;
  --grey: #f1f1ed;
  --grey-hover: #ebeae6;
  --black: #000;
  --white: #fff;
  --text-color: #0a0f1c;
  --font-family: "Roboto";

  // Default layout
  --default-layout-header-height: 82px;
  --default-layout-width: 1600px;
  --small-layout-width: 1024px;
  --default-layout-horizontal-spacer: 60px;
  --min-size-mobile: 320px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);

  font-size: 1.6rem;
  line-height: 1.5;
  text-rendering: optimizespeed;
  color: var(--text-color);
  overflow-y: overlay;
}

// Scrollbar CSS
html *::-webkit-scrollbar {
  border-radius: 0;
  width: 8px;
}

html *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.15);
}

html *::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
}

button,
input,
[tabindex] {
  outline: none;
  border: none;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

// Fonts emmbeded
@font-face {
  font-family: Times;
  src: url("/assets/fonts/SVN-Times.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Times;
  src: url("/assets/fonts/SVN-Times-Bold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: Times;
  src: url("/assets/fonts/SVN-Times-Italic.ttf");
  font-style: italic;
}

@font-face {
  font-family: "Roboto Regular";
  src: url("/assets/fonts/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Roboto Medium";
  src: url("/assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto Italic";
  src: url("/assets/fonts/Roboto-Italic.ttf");
}

@font-face {
  font-family: "Roboto Bold";
  src: url("/assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: Lexend;
  src: url("/assets/fonts/Lexend-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Lexend;
  src: url("/assets/fonts/Lexend-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Lexend;
  src: url("/assets/fonts/Lexend-Bold.ttf");
  font-weight: 600;
}

// Custom tippy tooltip
body {
  .tippy-box {
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.2rem;
    background-color: rgba(84, 84, 84, 0.92);
  }

  .tippy-arrow {
    color: rgba(84, 84, 84, 0.92);
  }

  .tippy-content {
    padding: 8px 9px;
  }

  .tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
    top: -8px;
  }
}

ul li {
  list-style: none;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  :root {
    // Default layout
    --default-layout-header-height: 60px;
    --default-layout-horizontal-spacer: 12px;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .actions {
  }
}
