.main {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: calc(var(--default-layout-header-height) + 40px);
  font-family: "Arial";
  background-color: white;
}

.heading {
  text-align: center;
  color: #333;
  font-weight: 500;
}

.title-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.content {
  padding: 20px;
}

.text {
  font-size: 14px;
}

.password-container {
  display: flex;
  align-items: center;
  position: relative;
}

.show-password-button {
  background: none;
  border: none;
  position: absolute;
  right: 10px; // Khoảng cách từ phải của ô nhập
  cursor: pointer;
  padding: 0;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.bottom {
  border-top: 1px solid #ddd;
  padding: 12px 20px 0;
  display: flex;
  justify-content: center;
}
