.container {
  border: 1px solid red;
  border-radius: 4px;
  margin-bottom: 24px;
}

.myinput {
  display: none;
}

.mydisplay {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tick {
  height: 12px;
  width: 12px;
  border-radius: 2px;
  background-color: #ff7800;
}

.checkbox-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 20px;
}

.checkbox-item:active,
.checkbox-item:hover {
  background-color: #fafafa;
}

.radio-list {
  /* padding: 24px; */
}

.radio-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
}

.radio-item:active,
.radio-item:hover {
  background-color: #fafafa;
}

.radio-dot {
  margin-right: 12px;
}

.gas-container {
  padding-top: 20px;
  display: flex;
  align-items: center;
}

.gas-title {
  flex: 1;
}

.expand-container {
  padding: 0 20px 20px;
}

.count-button {
  border-radius: 4px;
  background-color: red;
  padding: 4px 8px;
  cursor: pointer;
}
