.sidebar {
  padding: 20px;
  background-color: #fff;
  width: 250px;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .profile-image {
    border-radius: 50%;
    width: 80px;
    height: 80px;
  }
}

.home-button {
  margin: 10px 0;
  width: 100%;
}

.account-info {
  margin-top: 20px;
  .balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-radius: 4px;
  }

  .balance:hover {
    background-color: rgba(0, 0, 0, 0.03);
    cursor: pointer;
  }

  .transfer {
    margin: 10px 0;
    .code {
      display: flex;
      justify-content: space-between;
    }
    .copy-button {
      background: none;
      border: none;
      cursor: pointer;
      color: #00f;
    }
  }
}

.deposit-button {
  width: 100%;
}

.menu {
  margin-top: 20px;

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 10px;
      a {
        text-decoration: none;
        color: #000;
      }
      .new,
      .save {
        background-color: #f00;
        color: #fff;
        padding: 2px 5px;
        border-radius: 3px;
        margin-left: 5px;
      }
    }
  }
}

.menu-toggle {
  display: none;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .sidebar {
    width: 100%;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .sidebar {
    width: 100%;
  }
}
