.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  min-width: 120px;
  padding: 10px 8px;
  border-radius: 4px;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "Arial";
  cursor: pointer;
  background-color: var(--white);
  border: 1px solid transparent;
  user-select: none;
  width: 100%;

  + .wrapper {
    margin-left: 8px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.icon + .title,
.title + .icon {
  margin-left: 8px;
}

.icon {
  display: inline-block;
  width: 24px;
  text-align: center;
}

// Button types
.rounded {
  border-radius: 999px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 6%);
  border-color: rgba(22, 24, 35, 0.12);
}

.primary {
  color: var(--white);
  background-color: var(--logo-color);
  border-color: var(--logo-color);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 13px 20px rgba(110, 129, 154, 0.6);
}

.outline {
  background-color: var(--grey);
}

.text {
  &:hover {
    text-decoration: underline;
  }
}

// Button sizes
.small {
  min-width: 88px;
  padding: 4px 16px;
}

.large {
  padding: 14px 16px;
  min-width: 140px;
}
