.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 100%;
  padding: 0px;
  width: 100%;
  max-width: 100%;
  margin-top: var(--default-layout-header-height);
  display: flex;
}

.sidebar-container {
  display: block;
}

.content {
  flex: 1;
  // justify-content: center;
  // display: flex;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .sidebar-container {
    display: none;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .sidebar-container {
    display: none;
  }
}
