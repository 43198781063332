.container {
  flex: 1;
  padding-right: 72px;
  padding-left: 72px;
  padding-top: 72px;
  padding-bottom: 124px;
  display: flex;
  justify-content: center;
}

.wrapper {
  max-width: 1456px;
  width: 100%;
}

.title {
  font: 400 24px/32px Work Sans, sans-serif;
  margin-bottom: 12px;
  text-align: center;
  color: #28293c;
  letter-spacing: -0.02em;
}

.sub-title {
  font: 400 16px/24px Work Sans, sans-serif;
  text-align: center;
  color: #28293c;
  margin-bottom: 40px;
}

.list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.imageWrapper {
  height: 120px;
  width: 180px;
}

.image {
  height: 60px;
  width: 100%;
  object-fit: contain;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .container {
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 24px;
    padding-bottom: 48px;
  }

  .imageWrapper {
    height: 80px;
    width: 160px;
  }

  .image {
    height: 60px;
    width: 100%;
    object-fit: contain;
  }
}
