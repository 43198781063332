.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  label {
    font-size: 16px;
    font-weight: 600;
    color: #666;
  }

  .filterSelect {
    margin-left: 10px;
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
}

.tableWrapper {
  overflow-x: auto;

  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    th,
    td {
      padding: 12px 15px;
      text-align: left;
    }

    th {
      background-color: #007bff;
      color: #fff;
      text-transform: uppercase;
    }

    td {
      background-color: #fff;
      border-bottom: 1px solid #eaeaea;
    }

    tr:nth-child(even) td {
      background-color: #f4f4f4;
    }
  }

  .status {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    color: #fff;

    &.shipped {
      background-color: #ffc107;
    }

    &.delivered {
      background-color: #28a745;
    }
  }
}

.buttonGroup {
  display: flex;
  gap: 10px;

  .button {
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &.cancelButton {
      background-color: #dc3545;
      color: #fff;

      &:hover {
        background-color: #c82333;
      }
    }

    &.completeButton {
      background-color: #28a745;
      color: #fff;

      &:hover {
        background-color: #218838;
      }
    }
  }
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 400px;

  h2 {
    margin-bottom: 20px;
    color: #333;
  }

  .cancelReasons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;

    .radioContainer {
      display: flex;
      align-items: center;
      cursor: pointer;

      .radio {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 2px solid #007bff;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .dot {
          width: 10px;
          height: 10px;
          background-color: #007bff;
          border-radius: 50%;
        }
      }

      .radioLabel {
        font-size: 16px;
        color: #333;
      }
    }
  }

  .popupButtonGroup {
    display: flex;
    justify-content: space-between;

    .popupButton {
      padding: 10px 20px;
      font-size: 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &.skipButton {
        background-color: #6c757d;
        color: #fff;

        &:hover {
          background-color: #5a6268;
        }
      }

      &.confirmButton {
        background-color: #007bff;
        color: #fff;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}
