/* RecruitmentForm.css */
.main {
  margin-top: var(--default-layout-header-height);
  font-family: "Arial";
  background-color: white;
}

.recruitment-form-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-heading {
  text-align: center;
  color: #333;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-label {
  margin: 10px 0;
  font-weight: bold;
}

.form-input {
  width: 100%;
  padding: 8px;
  margin: 5px 0 20px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-select {
  position: relative;
  width: 100%;
}

.form-select select {
  width: 100%;
  padding: 10px;
  margin: 5px 0 20px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("https://img.icons8.com/material-outlined/24/000000/expand-arrow--v1.png")
    no-repeat right center / 15px;
  cursor: pointer;
}

.form-select select::-ms-expand {
  display: none;
}

.form-select select option {
  padding: 10px;
  background-color: #fff;
}

.form-select select option:hover {
  background-color: #f0f0f0;
}

.form-select select option:checked {
  background-color: #e0e0e0;
}

.form-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.form-button:hover {
  background-color: #45a049;
}

.form-textarea {
  height: 100px;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .recruitment-form-container {
    width: 100%;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
}
