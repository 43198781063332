.main {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: calc(var(--default-layout-header-height) + 40px);
  font-family: "Arial";
}

.heading {
  text-align: center;
  color: #333;
  font-weight: 500;
}

.title-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.content {
  padding: 20px;
}

ul {
  list-style-type: none; /* Loại bỏ số thứ tự */
  padding-left: 0; /* Xóa padding mặc định của danh sách */
}

li {
  margin-bottom: 15px;
}

input {
  width: 100%;
  padding: 12px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  // box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.03);
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.bottom {
  border-top: 1px solid #ddd;
  padding: 12px 20px 0;
  display: flex;
  justify-content: center;
}
