.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 72px;
  padding-bottom: 124px;
}

.mainSection {
  margin-bottom: 24px;
  display: flex;
  max-width: var(--default-layout-width);
  padding-left: 72px;
  padding-right: 72px;
  width: 100%;
}

.left-mainSection {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.right-mainSection {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 40px;
}
.title {
  font: 400 56px/64px Work Sans, sans-serif;
  letter-spacing: -0.02em;
  color: #28293c;
  margin-bottom: 24px;
}
.subTitle {
  font: 400 16px/24px Work Sans, sans-serif;
  margin-bottom: 40px;
  padding-right: 15%;
  color: #28293c;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .mainSection {
    display: flex;
    flex-direction: column-reverse;
    padding-left: 16px;
    padding-right: 16px;
  }

  .right-mainSection {
    padding-left: 0;
    margin-bottom: 48px;
  }

  .title {
    font-size: 32px;
    line-height: normal;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .title {
    font-size: 42px;
    line-height: normal;
  }
}
