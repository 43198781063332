.main {
  margin-top: var(--default-layout-header-height);
  font-family: "Arial";
  background-color: white;
}

.top-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 96px 72px 128px;
}

.top {
  max-width: 1456px;
  width: 100%;
}

.content-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 96px 72px 128px;
  background-color: #e3f8ef;
  border-radius: 24px 24px 0 0;
}

.content {
  max-width: 1456px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .top-container {
    padding: 24px;
  }

  .content-container {
    padding: 24px;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
}
