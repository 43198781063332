.myinput {
  display: none;
}

.mydisplay {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.tick {
  height: 12px;
  width: 12px;
  border-radius: 2px;
  background-color: #ff7800;
}

.checkbox-item {
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.title {
  flex: 1;
}

.checkbox-item:active,
.checkbox-item:hover {
  background-color: #fafafa;
}
