.container {
  display: flex;
  padding: 24px 0;
  width: 100%;
  border-radius: 24px;
  cursor: pointer;
}

.imageContainer {
  height: 32px;
  width: 32px;
  margin-right: 16px;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.textContainer {
  display: flex;
  flex-direction: column;
}
