.container {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  width: 33%;
  // height: 160px;
  border-radius: 24px;
  cursor: pointer;
  margin-bottom: 12px;
}

.container:hover {
  background-color: #e3f8ef;
}

.imageContainer {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.label-title {
  margin-left: 12px;
}

.label-text {
  font-size: 14px;
}

.image {
  height: 32px;
  width: 32px;
  object-fit: contain;
}
