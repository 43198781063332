.modal-backdrop {
  position: fixed;
  top: var(--default-layout-header-height);
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 1000000000;
}

.modal-content {
  background-color: white;
  border-radius: 4px;
  position: relative;
  width: 100%;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  overflow-y: scroll;
  overscroll-behavior: contain;
}

.close-icon {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 100000000000000;
}

.login-button {
  padding: 20px;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .modal-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .modal-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
