.modal-backdrop {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  z-index: 100000000000;
  justify-content: center;
  align-items: center;
}

.modal-content {
  min-width: 320px;
  background-color: white;
  border-radius: 10px;
  position: relative;
  width: 60%;
  height: 60%;
  overflow: hidden;
}

.close-icon {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 100000000000000;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .modal-content {
    width: 100%;
    height: 100%;
  }

  .modal-content {
    border-radius: 0;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
  .modal-content {
    // border-radius: 0;
  }
}
