.container {
  // display: flex;
  // height: 100%;
}

.left {
  flex: 0.3;
  background-color: #f5f7fd;
  height: 100%;
  padding-left: 72px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 60px;
  display: flex;
  flex-direction: column;
}

.preHeading {
  font: 400 14px/22px Work Sans, sans-serif;
  margin-bottom: 32px;
  text-transform: uppercase;
}

.title {
  margin-bottom: 16px;
  font: 400 24px/32px Work Sans, sans-serif;
  letter-spacing: -0.02em;
  font-weight: 500;
}

.text {
  flex: 1;
  margin-bottom: 40px;
}

.right {
  padding-left: 24px;
  padding-top: 32px;
  padding-bottom: 40px;
  padding-right: 60px;
}

.preHeadingRight {
  font: 400 14px/22px Work Sans, sans-serif;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.cards {
  display: flex;
  flex-wrap: wrap;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .left {
    padding: 24px;
  }

  .right {
    padding: 24px;
  }
}
