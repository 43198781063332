.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 72px;
  padding-bottom: 124px;
}

.mainSection {
  display: flex;
  max-width: var(--default-layout-width);
  padding-right: 72px;
  padding-left: 72px;
}

.left-mainSection {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-right: 72px;
}

.right-mainSection {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 12px;
  max-width: 50%;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .container {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .mainSection {
    padding-right: 16px;
    padding-left: 16px;
    flex-direction: column-reverse;
  }
  .right-mainSection {
    padding: 0;
  }

  .left-mainSection {
    padding-right: 0;
    margin-bottom: 48px;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
}
