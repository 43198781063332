.main {
  margin-top: var(--default-layout-header-height);
  font-family: "Arial";
  background-color: white;
}

.top {
  max-width: 1456px;
  width: 100%;
  padding: 72px;
}

.content-container {
  width: 100%;
  display: flex;
  justify-content: center;
  // padding: 0 72px 128px;
  background-color: white;
}

.content {
  max-width: 1456px;
  width: 100%;
  border-radius: 24px 24px 0 0;
  background-color: white;
  transform: translateY(-100px);
  padding: 100px 144px;
  font-size: 1.6rem;
  line-height: 1.5;
  text-rendering: optimizespeed;
  color: var(--text-color);
}

.image-main {
  width: 100%;
  border-radius: 24px 24px 0 0;
}

.image-section {
  border-radius: 24px;
}

.space {
  height: 16px;
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .top {
    padding: 48px 16px;
  }

  .content {
    padding: 48px;
  }
}

// Tablet: 740px <= width < 1024px
@media only screen and (min-width: 46.25em) and (max-width: 63.9375em) {
}
