.container {
  background-color: #f7f4f1;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-top: 56px;
  padding-bottom: 62px;
  max-width: 100%;
  padding-right: 72px;
  padding-left: 72px;
  max-width: 100vw;
  display: flex;
  justify-content: center;
}

.wrapper {
  max-width: 1456px;
  width: 100%;
}

.title {
  padding-bottom: 12px;
  font: 400 24px/32px Work Sans, sans-serif;
  letter-spacing: -0.02em;
}

.text {
  font: 400 16px/24px Work Sans, sans-serif;
  color: #28293c;
}

.slider-container {
  padding-bottom: 32px;
  padding-top: 32px;
  width: 100%;
}

.navigation {
  display: flex;
  justify-content: flex-end;
}
.btn {
  margin-left: 16px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);

  &:hover {
    color: rgba(0, 0, 0, 0.9);
  }
}

// Mobile: width < 740px
@media only screen and (max-width: 46.1875em) {
  .container {
    padding-right: 16px;
    padding-left: 16px;
  }
}
