.privacy-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.privacy-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.privacy-text {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
  margin-bottom: 15px;
}
