.grid {
  display: flex;
  flex-direction: row;
}

.item {
  position: relative;
  height: 400px;
  background-color: lightGrey;
  overflow: hidden;
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 100%;

  .item__title {
    margin: 0;
    display: block;
    background-color: white;
    padding: 24px 24px 0 24px;
    border-radius: 20px;
    height: 15rem;
  }

  .item__title-first {
    margin-bottom: 12px;
    font: 400 20px/28px Work Sans, sans-serif;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .item__title-second {
    color: gray;
    padding-bottom: 12px;
    font: 400 16px/24px Work Sans, sans-serif;
  }

  .a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &:hover,
    &:focus {
      ~ .item__overlay {
        transform: translate3d(0, 0, 0);
        background-color: white;
      }
    }
  }
}

img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.item__overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  top: 0;
  transition: transform 300ms, background-color 300ms;
  transform: translate3d(0, calc(100% - 15rem), 0);
}

.item__body {
  flex-grow: 1;
  padding: 0 24px 24px 24px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .item__title-third {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 48px;
  }
}
